import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBadge.figmaUrl.ios} codeUrl={checklists.componentBadge.codeUrl.ios} checklists={checklists.componentBadge.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`LGNBadge`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`LGNBadge`}</inlineCode>{` is a small status descriptor for UI elements. It can be used to display a status or a count of items.
It may consists of a small circle, usually containing a number or other short character, that appears near other objects. `}</p>
    <p>{`You can use `}<inlineCode parentName="p">{`LGNBadge`}</inlineCode>{` with the following appearances:`}</p>
    <ul>
      <li parentName="ul">{`With text or without text`}</li>
      <li parentName="ul">{`With image or without image (both on left or right side)`}</li>
      <li parentName="ul">{`Badge size`}</li>
      <li parentName="ul">{`Color of badge`}</li>
    </ul>
    <h2>{`Usage`}</h2>
    <p>{`Example of basic error `}<inlineCode parentName="p">{`LGNBadge`}</inlineCode>{` without anything (only simple dot)`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/LGNBadge_dot.png",
      "alt": "usage iOS divider"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  LGNBadge(
      type: .error, 
      size: .medium)
`}</code></pre>
    <hr></hr>
    <p>{`Example of large primary `}<inlineCode parentName="p">{`LGNBadge`}</inlineCode>{` using text only`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/LGNBadge_text_only.png",
      "alt": "usage iOS divider"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  LGNBadge(
    label: "999", 
    type: .primary, 
    size: .large,
    leftImage: Image(systemName: "heart"))
`}</code></pre>
    <hr></hr>
    <p>{`Example of `}<inlineCode parentName="p">{`LGNBadge`}</inlineCode>{` with text and icon on both left and right side`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/LGNBadge_text_and_image.png",
      "alt": "usage iOS divider"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    LGNBadge(
      label: "999",
      type: .secondary,
      size: .large,
      leftImage: Image(systemName: "heart.fill"),
      rightImage: Image(systemName: "key"))
`}</code></pre>
    <div className="divi" />
    <h2><strong parentName="h2">{`Attributes`}</strong></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text content for badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A BadgeType that represents the color of the badge.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A BadgeSize that represents the size of the badge.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LeftImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An Image that represents the image on the left side of the badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RightImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An Image that represents the image on the right side of the badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      